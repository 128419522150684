<template>
    <v-container>
        <v-row class="my-10">
            <v-col cols="12" class="d-md-flex">
                <h2 class="primary--text main-heading">
                    NETWORKING OPPORTUNITIES
                </h2>
                <v-divider class="mx-4" inset vertical></v-divider>
                <v-spacer></v-spacer>
                <v-btn
                    color="secondary primary--text"
                    dark
                    class="hover mb-2 pa-7"
                    :to="{ name: 'NetworkingOpportunitiesCreate' }"
                >
                    <v-icon small left> fa fa-plus </v-icon>
                    Create New
                </v-btn>
            </v-col>
            <v-col
                cols="12"
                class="mb-10"
                :style="`min-height: ${
                    myOpportunities.length >= 1 ? '230px' : '100px'
                }`"
            >
                <h2 class="main-heading">My opportunities</h2>
                <template v-if="myOpportunities.length >= 1">
                    <v-slide-group :show-arrows="true">
                        <v-slide-item
                            v-for="item in myOpportunities"
                            :key="item.id"
                            style="max-width: 300px"
                        >
                            <networking-opportunities-card
                                :item="item"
                                class="my-5 mx-3"
                                @click="
                                    $router.push({
                                        name: 'NetworkingOpportunity',
                                        params: { id: item.id },
                                    })
                                "
                            ></networking-opportunities-card>
                        </v-slide-item>
                    </v-slide-group>

                    <div class="text-center more_oportunities_btn">
                        <router-link
                            class="primary--text"
                            :to="{ name: 'MyNetworkingOpportunities' }"
                        >
                            See All
                        </router-link>
                    </div>
                </template>
                <div
                    v-else-if="
                        getOpportunitiesStatus_Pending ||
                        getOpportunitiesStatus_Idle
                    "
                >
                    <loading-spinner></loading-spinner>
                </div>
                <template v-else>
                    <div>You haven't created any requests/provisions yet</div>
                </template>
            </v-col>
            <v-col cols="12">
                <h2 class="main-heading">Other opportunities</h2>
            </v-col>
            <v-col
                v-if="
                    getOpportunitiesStatus_Pending ||
                    getOpportunitiesStatus_Idle
                "
                style="min-height: 400px"
                cols="12"
            >
                <loading-spinner></loading-spinner>
            </v-col>
            <v-col v-else-if="getOpportunitiesStatus_Error" cols="12">
                <span class="text-h6">
                    {{ $t("ErrorMessages.somethingWrong") }}
                </span>
            </v-col>
            <template v-else-if="opportunities.length >= 1">
                <v-col cols="12">
                    <v-row>
                        <v-col
                            v-for="item in opportunities"
                            :key="item.id"
                            cols="12"
                            sm="6"
                            md="4"
                            lg="3"
                        >
                            <networking-opportunities-card
                                :item="item"
                                @click="
                                    $router.push({
                                        name: 'NetworkingOpportunity',
                                        params: { id: item.id },
                                    })
                                "
                            ></networking-opportunities-card>
                        </v-col>
                    </v-row>
                </v-col>
            </template>
            <template v-else>
                <div>You haven't created any requests/provisions yet</div>
            </template>
        </v-row>
    </v-container>
</template>

<script>
import { apiStatus } from "../api/constants/apiStatus";
import { apiStatusComputed } from "../api/helpers/computedApiStatus";
import { withAsync } from "@/helpers/withAsync";
import {
    getUserOpportunities,
    getAllOpportunities,
} from "@/api/networkingOpportunitiesApi.js";

import NetworkingOpportunitiesCard from "./NetworkingOpportunitiesCard.vue";
import LoadingSpinner from "../components/LoadingSpinner.vue";
export default {
    name: "NetworkingOpportunities",
    components: { NetworkingOpportunitiesCard, LoadingSpinner },

    data() {
        return {
            opportunities: [],

            myOpportunities: [],

            getOpportunitiesStatus: apiStatus.Idle,
            getMyOpportunities: apiStatus.Idle,
        };
    },

    computed: {
        ...apiStatusComputed(["getOpportunitiesStatus", "getMyOpportunities"]),
    },
    methods: {
        async handleOpportunities() {
            this.getOpportunitiesStatus = apiStatus.Pending;

            const { response, error } = await withAsync(getAllOpportunities);

            if (error) {
                this.getOpportunitiesStatus = apiStatus.Error;
                return;
            }

            this.opportunities = response.data.data;
            this.getOpportunitiesStatus = apiStatus.Success;
        },

        async handleUsersOpportunities() {
            this.getMyOpportunities = apiStatus.Pending;

            const { response, error } = await withAsync(getUserOpportunities);

            if (error) {
                this.getMyOpportunities = apiStatus.Error;
                return;
            }

            this.myOpportunities = response.data.data;
            this.getMyOpportunities = apiStatus.Success;
        },
    },

    created() {
        this.handleOpportunities();
        this.handleUsersOpportunities();
    },
};
</script>

<style scoped>
.v-slide-group__content {
    justify-content: center;
}
.more_oportunities_btn {
    position: absolute;
    right: 0;
    margin-right: 250px;
}
</style>
