var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',{staticClass:"my-10"},[_c('v-col',{staticClass:"d-md-flex",attrs:{"cols":"12"}},[_c('h2',{staticClass:"primary--text main-heading"},[_vm._v(" NETWORKING OPPORTUNITIES ")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-spacer'),_c('v-btn',{staticClass:"hover mb-2 pa-7",attrs:{"color":"secondary primary--text","dark":"","to":{ name: 'NetworkingOpportunitiesCreate' }}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v(" fa fa-plus ")]),_vm._v(" Create New ")],1)],1),_c('v-col',{staticClass:"mb-10",style:(("min-height: " + (_vm.myOpportunities.length >= 1 ? '230px' : '100px'))),attrs:{"cols":"12"}},[_c('h2',{staticClass:"main-heading"},[_vm._v("My opportunities")]),(_vm.myOpportunities.length >= 1)?[_c('v-slide-group',{attrs:{"show-arrows":true}},_vm._l((_vm.myOpportunities),function(item){return _c('v-slide-item',{key:item.id,staticStyle:{"max-width":"300px"}},[_c('networking-opportunities-card',{staticClass:"my-5 mx-3",attrs:{"item":item},on:{"click":function($event){return _vm.$router.push({
                                    name: 'NetworkingOpportunity',
                                    params: { id: item.id },
                                })}}})],1)}),1),_c('div',{staticClass:"text-center more_oportunities_btn"},[_c('router-link',{staticClass:"primary--text",attrs:{"to":{ name: 'MyNetworkingOpportunities' }}},[_vm._v(" See All ")])],1)]:(
                    _vm.getOpportunitiesStatus_Pending ||
                    _vm.getOpportunitiesStatus_Idle
                )?_c('div',[_c('loading-spinner')],1):[_c('div',[_vm._v("You haven't created any requests/provisions yet")])]],2),_c('v-col',{attrs:{"cols":"12"}},[_c('h2',{staticClass:"main-heading"},[_vm._v("Other opportunities")])]),(
                _vm.getOpportunitiesStatus_Pending ||
                _vm.getOpportunitiesStatus_Idle
            )?_c('v-col',{staticStyle:{"min-height":"400px"},attrs:{"cols":"12"}},[_c('loading-spinner')],1):(_vm.getOpportunitiesStatus_Error)?_c('v-col',{attrs:{"cols":"12"}},[_c('span',{staticClass:"text-h6"},[_vm._v(" "+_vm._s(_vm.$t("ErrorMessages.somethingWrong"))+" ")])]):(_vm.opportunities.length >= 1)?[_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',_vm._l((_vm.opportunities),function(item){return _c('v-col',{key:item.id,attrs:{"cols":"12","sm":"6","md":"4","lg":"3"}},[_c('networking-opportunities-card',{attrs:{"item":item},on:{"click":function($event){return _vm.$router.push({
                                    name: 'NetworkingOpportunity',
                                    params: { id: item.id },
                                })}}})],1)}),1)],1)]:[_c('div',[_vm._v("You haven't created any requests/provisions yet")])]],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }