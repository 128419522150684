<template>
  <v-container>
    <v-row class="my-10">
        <v-col cols="12" class="d-md-flex">
            <div class="d-flex">
                <v-btn
                    icon
                    color="accent darken-3"
                    class="align-self-center"
                    link
                    @click="$router.go(-1)"
                >
                    <v-icon left> fa-solid fa-chevron-left </v-icon>
                </v-btn>
                <h2 class="primary--text main-heading">
                    Updating networking opportunity
                </h2>
            </div>
            <v-divider class="mx-4" inset vertical> </v-divider>
            <v-spacer></v-spacer>
        </v-col>

        <v-col cols="12">
            <base-card>
                <template #content>
                   <v-card-title class="main-heading primary--text">
                        <div class="d-flex align-center">
                            <v-icon left color="primary">
                                fas fa-pen
                            </v-icon>
                            <h1 class="main-heading">
                                Update your request/provision
                            </h1>
                        </div>
                    </v-card-title>
                    <v-card-text>
                        <opportunities-form
                            :item="item"
                            :validation-errors="validationErrors"
                            :handle-loading="loadingButton"
                            @submit-form="handleUpdate($event)"
                        ></opportunities-form>
                    </v-card-text>
                </template>
            </base-card>
        </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { apiStatus } from "../api/constants/apiStatus";
import { apiStatusComputed } from "../api/helpers/computedApiStatus";
import { withAsync } from "@/helpers/withAsync";
import {
    showOpportunity,
    updateOpportunity,
} from "../api/networkingOpportunitiesApi";

import BaseCard from "../components/base/BaseCard.vue";
import OpportunitiesForm from "./NetworkingOpportunitiesForm.vue";
export default {
    name: "NetworkingOpportunitiesCreate",

    props: {
        id: {
            type: [String, Number],
            required: true,
        },
    },
    components: { OpportunitiesForm, BaseCard },
    data() {
        return {
            updateOpportunityStatus: apiStatus.Idle,
            getOpportunityStatus: apiStatus.Idle,
            item: {},
            validationErrors: [],
            loadingButton: false,
        };
    },

    computed: {
        ...apiStatusComputed(["updateOpportunityStatus"]),
    },

    methods: {
        async handleOpportunity() {
            this.getOpportunityStatus = apiStatus.Pending;

            const { response, error } = await withAsync(showOpportunity, {
                id: this.id,
            });

            if (error) {
                this.getOpportunityStatus = apiStatus.Error;
                return;
            }
            const data = response.data.data;

            this.item = this.reducePayload(data);
            this.getOpportunityStatus = apiStatus.Success;
        },
        async handleUpdate(payload) {
            this.updateOpportunityStatus = apiStatus.Pending;
            this.loadingButton = true;

            const splitPayload = {
                id: this.id,
                data: { ...payload },
            };

            const { response, error } = await withAsync(
                updateOpportunity,
                splitPayload
            );

            if (error) {
                this.updateOpportunityStatus = apiStatus.Error;
                this.validationErrors = error.response.data.errors;
                this.loadingButton = false;
                return;
            }

            this.updateOpportunityStatus = apiStatus.Success;
            this.loadingButton = false;
            this.$router.replace({
                name: "MyNetworkingOpportunities"
            });
        },

        reducePayload(payload) {
            const reduced = Object.entries(payload).reduce(
                (acc, [key, value]) => {
                    let computedValue;
                    if (Array.isArray(value)) {
                        computedValue = value.map((item) => item.id);
                    } else if (typeof value === "object")
                        computedValue = value.id;
                    else if (key.includes("date")) {
                        const [date, time] = value.split(" ");
                        computedValue = date;
                    } else computedValue = value;
                    acc[key] = computedValue;
                    return acc;
                },
                {}
            );

            return reduced;
        },
    },

    created() {
        this.handleOpportunity();
    },
};
</script>
