<template>
    <form style="min-height: 500px;">
        <v-row v-if="formParamsStatus_Pending || formParamsStatus_Idle" class="d-flex">
            <v-col style="min-height: 400px">
                <loading-spinner></loading-spinner>
            </v-col>
        </v-row>
        <v-row
          v-else
          class="mb-10"
        >
            <v-col
                v-for="(formValue, key, idx) in formValues"
                :key="idx"
                cols="12"
                :md="formValue.cols || 12"
            >
                <text-input
                    v-if="formValue.type === 'text'"
                    :input-value.sync="formValue.model"
                    :current-value="formValue.model"
                    :in-valid.sync="formValue.inValid"
                    :validation.sync="validationErrors"
                    :label="$t(`NetworkingOpportunities.form.${key}`)"
                ></text-input>

                <v-textarea
                    v-else-if="formValue.type === 'textarea'"
                    v-model="formValue.model"
                    outlined
                    :label="$t(`NetworkingOpportunities.form.${key}`)"
                ></v-textarea>

                <select-input
                    v-else-if="formValue.type === 'select'"
                    :input-value.sync="formValue.model"
                    :current-value="formValue.model"
                    :in-valid.sync="formValue.inValid"
                    :items="formParams[formValue.items]"
                    :multiple="formValue.multiple"
                    :label="$t(`NetworkingOpportunities.form.${key}`)"
                ></select-input>
                <date-picker
                    v-else-if="(formValue.type = 'date')"
                    v-model="formValue.model"
                    :in-valid.sync="formValue.inValid"
                    :label="$t(`NetworkingOpportunities.form.${key}`)"
                ></date-picker>
            </v-col>
           <v-row class="align-center mr-4">
            <v-col
              cols="12"
              md="6"
              sm="12"
              class="d-flex justify-center justify-md-start mt-5"
            >
                <ul v-if="validationErrors.length != 0">
                  <div class="d-flex mr-4">
                    <v-icon
                      small
                      left
                      color="warning"
                    >
                       fa fa-warning
                    </v-icon>
                    <h3 class="main-heading">
                      Form failed to Submit!
                    </h3>
                  </div>
                  <li
                    v-for="(item, index) in validationErrors"
                    :key="index"
                    style="list-style: none;"
                    class="error--text text-sm-center"
                  >
                    {{ item.toString() }}
                  </li>
                </ul>
            </v-col>
             <v-col
              cols="12"
              md="6"
              sm="12"
              class="text-end d-flex justify-center justify-md-end mt-5"
            >
                <v-btn
                    :disabled="!isValid"
                    outlined
                    depressed
                    color="primary"
                    class="mr-4"
                    @click="submitForm(0)"
                    >
                    Save as draft
                </v-btn>
                <v-btn
                    :disabled="!isValid"
                    depressed
                    color="primary"
                    :loading="handleLoading"
                    @click="submitForm(1)"
                    >
                    Publish
                </v-btn>
            </v-col>
           </v-row>
        </v-row>
    </form>
</template>

<script>
import { apiStatus } from "../api/constants/apiStatus";
import { apiStatusComputed } from "../api/helpers/computedApiStatus";
import { withAsync } from "@/helpers/withAsync";
import { getParams } from "../api/networkingOpportunitiesApi";

import TextInput from "../components/Inputs/TextInput.vue";
import SelectInput from "../components/Inputs/SelectInput.vue";
import DatePicker from "../components/Inputs/DatePicker.vue";

import LoadingSpinner from "../components/LoadingSpinner.vue";

export default {
    name: "NetworkingOpportunitiesForm",

    props: {
        validationErrors: {
            type: [Object, Array],
            required: false,
            default: null,
        },
        handleLoading: {
            type: Boolean,
            required: false,
            default: false,
        },
        item: {
            type: Object,
            required: false,
            default: () => {},
        },
    },

    components: { TextInput, SelectInput, DatePicker, LoadingSpinner },

    data() {
        return {
            formValues: {
                partnership: {
                    model: null,
                    inValid: true,
                    type: "select",
                    items: "partnerships",
                    multiple: false,
                },
                title: {
                    model: "",
                    inValid: true,
                    type: "text",
                },
                description: {
                    model: null,
                    inValid: false,
                    type: "textarea",
                },
                partnership_types: {
                    model: null,
                    inValid: true,
                    type: "select",
                    items: "partnership_types",
                },
                partnership_countries: {
                    model: null,
                    inValid: true,
                    type: "select",
                    items: "countries",
                },
                date_from: {
                    model: null,
                    inValid: true,
                    type: "date",
                    cols: 6,
                },
                date_to: {
                    model: null,
                    inValid: true,
                    type: "date",
                    cols: 6,
                },
            },
            formParams: {},
            formParamsStatus: apiStatus.Idle,
        };
    },

    computed: {
        ...apiStatusComputed(["formParamsStatus"]),
        isValid() {
            if (!this.formValues.description.model) return false;

            for (let objValue of Object.values(this.formValues)) {
                if (objValue.inValid) {
                    return false;
                }
            }
            return true;
        },
    },

    methods: {
        async handleFormParams() {
            this.formParamsStatus = apiStatus.Pending;

            const { response, error } = await withAsync(getParams);
            if (error) {
                this.formParamsStatus = apiStatus.Error;
                return;
            }
            const params = response.data.data;
            Object.entries(params).forEach(([key, value]) => {
                this.$set(this.formParams, key, value);
            });

            this.formParamsStatus = apiStatus.Success;
        },

        createPayload(status) {
            const reducedPayload = Object.entries(this.formValues).reduce(
                (acc, [key, value]) => {
                    acc[key] = Array.isArray(value.model)
                        ? [...value.model]
                        : value.model;
                    return acc;
                },
                {}
            );
            return { ...reducedPayload, status };
        },
        submitForm(status) {
            const payload = this.createPayload(status);
            this.$emit("submit-form", payload);
            this.$emit("loading");
        },
    },

    watch: {
        item: {
            handler(newVal) {
                if (!newVal) return;
                if (Object.keys(newVal).length > 0) {
                    for (let [key, value] of Object.entries(this.formValues)) {
                        if (key in newVal) {
                            value.model = newVal[key];
                            value.inValid = false;
                        }
                    }
                }
            },
            immediate: true,
        },
    },

    created() {
        this.handleFormParams();
        console.log('fdsfsdf', this.item);
    },
};
</script>
