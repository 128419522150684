<template>
  <v-container>
     <v-row class="my-10">
        <v-col cols="12" class="d-flex">
            <div class="d-flex">
                <v-btn
                    icon
                    color="accent darken-3"
                    class="align-self-center"
                    link
                    @click="$router.go(-1)"
                >
                    <v-icon left> fa-solid fa-chevron-left </v-icon>
                </v-btn>
                <h2 class="primary--text main-heading">
                    Creating networking opportunity
                </h2>
            </div>
            <v-divider class="mx-4" inset vertical> </v-divider>
            <v-spacer></v-spacer>
        </v-col>

        <v-col cols="12">
            <base-card>
                <template #content>
                    <v-card-title class="main-heading primary--text">
                        <div class="d-flex align-center">
                            <v-icon left color="primary">
                                fas fa-pen
                            </v-icon>
                            <h1 class="main-heading">
                                Create your request/provision
                            </h1>
                        </div>
                    </v-card-title>
                    <v-card-text>
                        <opportunities-form
                            :validation-errors="validationErrors"
                            :handle-loading="loadingButton"
                            @submit-form="handleCreate($event)"
                        ></opportunities-form>
                    </v-card-text>
                </template>
            </base-card>
        </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { apiStatus } from "../api/constants/apiStatus";
import { apiStatusComputed } from "../api/helpers/computedApiStatus";
import { withAsync } from "@/helpers/withAsync";
import { createOpportunity } from "../api/networkingOpportunitiesApi";

import BaseCard from "../components/base/BaseCard.vue";
import OpportunitiesForm from "./NetworkingOpportunitiesForm.vue";
export default {
    name: "NetworkingOpportunitiesCreate",

    components: { OpportunitiesForm, BaseCard },
    data() {
        return {
            createOpportunityStatus: apiStatus.Idle,
            validationErrors: [],
            loadingButton: false,
        };
    },

    computed: {
        ...apiStatusComputed(["createOpportunityStatus", "formParamsStatus"]),
    },

    methods: {
        async handleCreate(payload) {
            this.createOpportunityStatus = apiStatus.Pending;
            this.loadingButton = true;

            const { response, error } = await withAsync(
                createOpportunity,
                payload
            );

            if (error) {
                this.createOpportunityStatus = apiStatus.Error;
                this.validationErrors = error.response.data.metadata.response_message;
                this.loadingButton = false;
                return;
            }

            this.opportunities = response.data.data;
            this.createOpportunityStatus = apiStatus.Success;
            this.loadingButton = false;
            this.$router.replace({
                name: "MyNetworkingOpportunities"
            });
        },
    },
};
</script>
