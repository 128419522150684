<template>
    <v-card
        class="shadow pa-2 d-flex flex-column justify-space-between"
        :ripple="false"
        height="445"
        @click="$emit('click')"
    >
        <v-card-text class="pa-2">
            <div class="card__title mb-4">
                {{ item.title }}
            </div>
            <div class="mb-4">
                {{ item.partnership.name }}
            </div>
            <div
                v-if="
                    item.partnership_countries &&
                    item.partnership_countries.length > 0
                "
            >
                <v-icon color="primary" left>fas fa-earth-europe</v-icon>
                <template v-for="(country, idx) in item.partnership_countries">
                    <span :key="idx">
                        {{ country.name }}
                    </span>
                    <span
                        v-if="idx < item.partnership_countries.length - 1"
                        :key="`dash-${idx}`"
                    >
                        -
                    </span>
                </template>
            </div>
            <div
                v-if="
                    item.partnership_types && item.partnership_types.length > 0
                "
                class="mt-6"
            >
                <v-icon color="purple" left>fas fa-handshake</v-icon>
                <span v-for="(type, idx) in item.partnership_types" :key="idx">
                    {{ type.name }}
                </span>
            </div>
            <div class="mt-6 card__description">
                {{ item.description }}
            </div>
        </v-card-text>
        <div>
            <template v-if="hasActions">
                <v-divider class="my-4"></v-divider>
                <v-card-actions class="d-flex justify-lg-space-between">
                    <v-btn
                        class="pa-5 pr-8"
                        color="primary"
                        text
                        outlined
                        depressed
                        @click.stop="$emit('edit-item')"
                    >
                        <v-icon x-small left> fa-solid fa-pencil </v-icon>
                        {{ $t("SME.SolutionCard.editButton") }}
                    </v-btn>
                    <v-btn
                        class="pa-5 pr-8"
                        color="error"
                        text
                        outlined
                        depressed
                        @click.stop="$emit('delete-item')"
                    >
                        <v-icon x-small left> fa-solid fa-trash </v-icon>
                        {{ $t("SME.SolutionCard.deleteButton") }}
                    </v-btn>
                </v-card-actions>
            </template>
            <div class="d-flex align-center pa-3">
                <v-icon class="mr-4">fas fa-calendar-days</v-icon>
                <span class="text-body-2">
                    {{ parseDate(item.date_from) }} -
                    {{ parseDate(item.date_to) }}
                </span>
            </div>
        </div>
    </v-card>
</template>

<script>
export default {
    name: "NetworkingOpportunitiesCard",

    props: {
        item: {
            type: Object,
            required: true,
        },

        hasActions: {
            type: Boolean,
            required: false,
            default: false,
        },
    },

    data() {
        return {};
    },

    methods: {
        parseDate(timestamp) {
            const [date, time] = timestamp.split(" ");
            const [year, month, day] = date.split("-");
            return `${day}/${month}/${year}`;
        },
    },
};
</script>

<style scoped>
.shadow {
    box-shadow: 0px 0px 20px rgba(10, 52, 67, 0.25) !important;
}

.card__title {
    font-size: 1.2rem;
    font-weight: 500;
    color: var(--v-accent-darken4);
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1; /* start showing ellipsis when 1st line is reached */
    white-space: pre-wrap; /* let the text wrap preserving spaces */
}

.card__description {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4; /* start showing ellipsis when 1st line is reached */
    white-space: pre-wrap; /* let the text wrap preserving spaces */
}
.calendar_position {
}
</style>
